// eslint globals
/* global AOS */
document.addEventListener('DOMContentLoaded', function () {


  // animation
  AOS.init({
    once: true
  });


}, false);
